$color-red: #be102a;
//$color-blue: #151a45;
$color-blue: #002d62;
$color-white: #fff;
$color-lightgrey: #eee;
$color-darkgrey: #222;

$breakpoint-xs: 424.98px;
$breakpoint-sm: 575.98px;
$breakpoint-md: 767.98px;
$breakpoint-lg: 991.98px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1400px;

@mixin minion-font {
    font-family: "minion-pro", serif;
    font-weight: 500;
    font-style: normal;
}

@mixin sports-icon {
    width: 1.25rem;
    height: 1.5rem;
    background-size: 1.5rem;
    display: inline-block;
    margin-right: .25rem;
}

@mixin baseball-icon {
    background: no-repeat bottom center / 1.25rem url('http://usaprimeil.com/i/PrimeILBaseballLogo_64.png');
}

@mixin softball-icon {
    background: no-repeat bottom center / 1.25rem url('http://usaprimeil.com/i/PrimeILSoftballLogo_64.png');
}


@mixin baseball-softball-icon {
    background: no-repeat bottom left / 1.25rem url('http://usaprimeil.com/i/PrimeILBaseballLogo_64.png'), 
            no-repeat bottom right / 1.25rem url('http://usaprimeil.com/i/PrimeILSoftballLogo_64.png');
}

