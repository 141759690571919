@import '../../global.styles.scss';

#sponsors {
    background-color: $color-lightgrey;

    h2 {
        font-weight: bold;
        color: $color-blue;
        font-size: 1.5rem;
        margin-top: .75rem;
        text-align: center;
    }

    .level-4, .level-3 {
        a{
            display: block;
            text-align: center;
            margin-bottom: 1rem;

            img{
                max-width: 100%;
                padding: .25rem;
            }
        }
    }

    .level-4 a{
        border: .25rem solid $color-red;
        border-radius: .25rem;
    }

    .level-2, .level-1 {
        a img{
            margin-bottom: 1rem;
            max-width: 49%;
            
            padding: .25rem;
        }
    } 
}
  