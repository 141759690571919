@import '../../global.styles.scss';

.content-view.about{

    img{
        margin-left: 1rem;
        margin-right: 1rem;

        &.float-start {
            max-width: 12rem;
        }

        &.float-end {
            max-width: 12rem;
        }
    }

    ul.fundraising li {
        display: flex;

        .level{
            width: 10em;
        }

        .includes{
            width: 100%;
            padding-right: 3rem;
        }
    }


    ul.exec-board li::before {
        content: "";
    }
}

@media ( max-width: $breakpoint-sm ) {
    .content-view.about{
        ul.fundraising li {
            .level {
                width: 5rem;
            }

            .includes {
                padding-right: 0;
            }
        }
    }
}