@import '../../global.styles.scss';

.content-view.teams{
    section {
        display: none;

        .sports-icon{
            @include sports-icon;
            height: 1.25rem;
            background-size: 1.25rem;
        }
    }
    
    &.show-baseball section.baseball, &.show-softball section.softball{
        display: block;

    }

    section.baseball .sports-icon{
        @include baseball-icon;
    }

    section.softball .sports-icon{
        @include softball-icon;
    }

    h3 {
        border-bottom: .15rem solid $color-red;
    }

    h4 {
        margin-bottom: 0;
    }

    .players {
        width: 49%;
        margin-left: 2%;

        .commit {
            margin-left: -.5rem;
            font-style: italic;
            color: $color-blue;
        }
    }
}

@media ( max-width: $breakpoint-sm ) {
    .content-view.teams{
        .players{
            width: auto;
        }

        .team-members{
            flex-direction: column!important;
        }
    }
}