@import '../../global.styles.scss';

.tournament{
    
    tbody, tfoot{
        tr{
            border-top: 1px solid $color-lightgrey;
        }
    }

    tbody td{
        padding-top: .5em;
        padding-bottom: .5rem;
    }

    tfoot {
        font-size: .85rem;
        
        td {
            padding-bottom: 1.5rem;
        }
    }

    ol.tournament-results {
        padding-left: 1rem;
        margin: 0;
    }
}