@import '../../global.styles.scss';

#social-media-bar {
    background-color: $color-blue;
    text-align: right;
    color: $color-white;
    padding-top: .25rem;
    padding-bottom: .25rem;

    a {
        color: $color-white;
        text-decoration: none;
        padding-left: .5rem;
        padding-right: .5rem;
        border-left: 1px solid $color-white;

        &:first-of-type {
            border-left-width: 0;
        }

        &:last-of-type {
            padding-right: 0;
        }
    }
}

@media ( max-width: $breakpoint-md ) {
    #social-media-bar {
        text-align: center;
    }
}

header.site-header {
    @include minion-font;
    color: $color-blue;
    background-color: $color-white;
    font-weight: bold;
    text-transform: uppercase;
    -webkit-text-stroke: .5px $color-red;
    stroke-width: .5px;
    stroke: $color-red;

    img {
        max-height: 6rem;
        margin: .75rem;
        float: left;
    }

    h1 {
        font-size: 4rem;
        margin-bottom: -.5rem;
    }
    h2 {
        font-size: 2rem;
    }
}

@media ( max-width: $breakpoint-lg ) {
    header.site-header {
        h1{
            font-size: 3rem;
        }

        h2 {
            font-size: 1.5rem;
        }

        img {
            max-height: 4.5rem;
        }
    }
}

@media ( max-width: $breakpoint-md ) {
    header.site-header {
        h1{
            font-size: 2.5rem;
        }

        h2 {
            font-size: 1.25rem;
        }

        img {
            max-height: 3rem;
        }
    }
}

@media ( max-width: $breakpoint-sm ) {
    header.site-header {
        h1{
            font-size: 2rem;
        }

        h2 {
            font-size: 1.1rem;
        }

        img {
            max-height: 3rem;
        }
    }
}

@media ( max-width: $breakpoint-xs ) {
    header.site-header {
        h1{
            font-size: 1.75rem;
        }

        h2 {
            font-size: 0.85rem;
            line-height: 1.5rem;
        }

        img {
            max-height: 3rem;
        }
    }
}