@import '../../global.styles.scss';

nav.site-nav {
    background-color: $color-white;
    border-bottom: .25rem solid $color-red;
    position: relative;
    margin-top: -.5rem;
    
    .col-md-12.text-start{
        position: relative;
    }
       
    a{
        text-decoration: none;
        display: inline-block;
        color: $color-red;
        padding-top: .35rem;
        padding-bottom: .25rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        border-top-left-radius: .5rem;
        border-top-right-radius: .5rem;
        background-color: transparent;

        
            &.active, &:hover {
                color: $color-white;
            }

            &:hover{
                background-color: $color-red;
                transition: ease .5s;
            }

            &.active{
                background-color: $color-red;
                font-weight: bold;
                transition: none;
            }
    }

    .sports-filter{
        position: absolute;
        bottom: .25rem;
        right: 0;

        span.label {
            vertical-align: middle;
        }

        .btn-group{
            margin-left: .25rem;
        }

        .btn {
            border-color: $color-red;
            color: $color-red;
            width: 6.5rem;
            padding-left: .25rem;
            padding-right: .25rem;
            color: $color-darkgrey;
            background-color: $color-lightgrey;

            &.active, &.active:hover {
                background-color: $color-red;
                color: $color-white;
                font-weight: normal;

                .sport-icon{
                    filter: none !important;
                }
            }

            &:hover{
                color: $color-red;
                font-weight: bold;
                background-color: $color-white;
                
                .sport-icon{
                    filter: none !important;
                }
            }

            &.prevent-click{
                cursor: not-allowed;                
            }

            .sport-icon{
                @include sports-icon;
                height: 1.25rem;
                background-size: 1.25rem;
                margin-bottom: -.25rem;
                filter: grayscale(100%);
            }

            &.baseball .sport-icon{
                @include baseball-icon;
            }

            &.softball .sport-icon{
                @include softball-icon;
            }
        }

    }

}



@media ( max-width: $breakpoint-md ){
    nav.site-nav {
        margin-top: .5rem;
        .sports-filter{
            right: 1rem;
            bottom: .1rem;
        }
    }
}

@media ( max-width: $breakpoint-sm ){
    nav.site-nav {
        margin-top: .5rem;
        .sports-filter{
            //text-align: center;
            //position: relative;

            .label > span {
                display: none;
            }

            .btn {
                width: 3rem;

                .button-text{
                    display: none;
                }
            }
        }
    }
}