@import '../../global.styles.scss';

article.tournament-recap{
    display: flex;
    flex-direction: row;
    
    header .old-name{
        font-weight: normal;
        color: $color-darkgrey;
    }

    section {
        //justify-content: center;
        display: inline-block;
        width: 49%;
        vertical-align: top;
        

        &:nth-child(even){
            margin-right: 2%;
        }

        h3{
            margin-bottom: 0;
        }

        img.team-photo {
            width: 100%;
            margin-left: 0;
            margin-right: 0;
        }
    }
}

@media ( max-width: $breakpoint-sm ) {
    article.tournament-recap{
        section {
            width: 100%;
        }
    }
}