@import '../../global.styles.scss';

footer{
    position: relative;
    background-color: $color-red;
    color: $color-white;
    padding-top: 1rem;
    padding-bottom: 1rem;

    a, a:link, a:visited, a:hover, a:focus, a:active{
        color: $color-white;
    }

    .text-start {
        span {
            @include minion-font;
            font-weight: bold;
            font-size: 1.15rem;
        }
    }

    .text-center {
        min-height: 6rem;
    }
}

@media ( max-width: $breakpoint-md ) {
    footer {
        .text-center{ min-height: auto; }
        .text-end { display: none ; }
    }
}

    