@import '../../global.styles.scss';

.content-view.blog{

    &.showBaseball > article.baseball, &.showSoftball > article.softball {
        display: block;
    }

    article {
        margin-right: 1.5rem;

        header {
            font-weight: bold;
            color: $color-blue;
            font-size: 1.5rem;
            border-bottom: .15rem solid $color-red;
            margin-bottom: .5rem;
            margin-top: .75rem;

            .sport-icon{
                @include sports-icon;
            }
        }

        &.alert {
            margin-top: 1rem;
            padding-top: 0;
            border: 2px solid $color-red;
            header {
                color: $color-red;
                border-bottom-color: $color-blue;
            }
        }

        &.baseball > header > .sport-icon{
            @include baseball-icon;
        }

        &.softball > header > .sport-icon{
            @include softball-icon;
        }

        &.baseball.softball > header > .sport-icon{
            @include baseball-softball-icon;
            width: 1.75em;

        }

        img{
            margin-left: 1rem;
            margin-right: 1rem;

            &.float-start {
                max-width: 12rem;
            }

            &.float-end {
                max-width: 12rem;
            }
        }

        .blue {
            color: $color-blue
        }

        .table-75{
            width: 75%;
        }

        .table>:not(caption)>*>* {
            padding-bottom: 0;
            padding-top: 0;
        }

        .clear{
            clear: both;
        }
        
    }

}

@media ( max-width: $breakpoint-md ) {
    .content-view.blog{
        article {
            margin-right: auto;

            img{
                &.float-start, &.float-end {
                    max-width: 9rem;
                }
            }
        }
    }
}

@media ( max-width: $breakpoint-sm ) {
    .content-view.blog{
        article {
            img{
                &.float-start, &.float-end {
                    max-width: 6rem;
                }
            }

            .table-75{
                width: 100%;
            }
        }
    }
}