@import '../global.styles.scss';

.content-view {
    color: $color-darkgrey;

    h2 {
        font-weight: bold;
        color: $color-blue;
        font-size: 1.5rem;
        margin-top: .75rem;
    }

    h3{
        font-weight: bold;
        color: $color-blue;
        font-size: 1.25rem;
    }

    h4 {
        font-weight: bold;
        font-size: 1.1rem;
    }
}